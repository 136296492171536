import React, { useEffect } from 'react';
import T from 'prop-types';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { glsp, media } from '@devseed-ui/theme-provider';
import { Heading } from '@devseed-ui/typography';

import { SupHeading } from '../styles/typography/supheading';
import { InpageNav } from '../styles/inpage';
import { listener } from '../utils/keypress';
import { getBlogTopicUrl, getProjectTopicUrl } from '../utils/utils';

const InpageNavBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${glsp()};
  grid-template-rows: min-content;

  &:last-child {
    ${media.largeUp`
      grid-column-start: content-10;
      grid-column-end: span 3;
    `}
  }
`;

const InpageNavBlockTopics = styled(InpageNavBlock)`
  grid-column: content-start / content-end;

  ${media.largeUp`
    grid-column-start: content-start;
    grid-column-end: span 6;
  `}
`;

const InpageNavBlockPrevious = styled(InpageNavBlock)`
  grid-column: content-start / content-end;

  ${media.largeUp`
    grid-column-start: content-7;
    grid-column-end: span 3;
  `}
`;

const InpageNavBlockNext = styled(InpageNavBlock)`
  grid-column: content-start / content-end;

  ${media.largeUp`
    grid-column-start: content-10;
    grid-column-end: span 3;
  `}
`;

const InpageNavBlockTitle = styled(SupHeading).attrs({
  as: 'h2',
  variation: 'base'
})``;

const InpageNavMenu = styled.ol`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;

  li {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    font-variation-settings: 'wdth' 96, 'wght' 512;
    margin: ${glsp(0, 1, 1, 0)};

    ${media.largeUp`
      margin: ${glsp(0, 2, 1, 0)};
    `}
  }

  a {
    display: block;
  }
`;

export const InpageNavItem = styled(Heading).attrs({ as: 'p' })`
  font-size: 1.5rem;
  line-height: 2rem;

  ${media.mediumUp`
    font-size: 2rem;
    line-height: 2.5rem;
  `}

  ${media.largeUp`
    font-size: 2.5rem;
    line-height: 3rem;
  `}

  a {
    display: block;
  }
`;

const InpageNavigation = (props) => {
  const { topics, type = 'blog', prevPost, nextPost } = props;

  useEffect(() => {
    const keyListenerLeft = () => {
      if (prevPost) {
        navigate(prevPost.url);
      }
    };
    const keyListenerRight = () => {
      if (nextPost) {
        navigate(nextPost.url);
      }
    };

    listener.simple_combo('left', keyListenerLeft);
    listener.simple_combo('right', keyListenerRight);
    return () => {
      listener.unregister_combo('left');
      listener.unregister_combo('right');
    };
  }, [prevPost, nextPost]);

  return (
    <InpageNav role='navigation'>
      {topics && topics.length && (
        <InpageNavBlockTopics>
          <InpageNavBlockTitle>Topics</InpageNavBlockTitle>
          <InpageNavMenu>
            {topics.map((t) => (
              <li key={t}>
                <Link
                  to={
                    type === 'project'
                      ? getProjectTopicUrl(t)
                      : getBlogTopicUrl(t)
                  }
                  title='Explore topic'
                >
                  {t}
                </Link>
              </li>
            ))}
          </InpageNavMenu>
        </InpageNavBlockTopics>
      )}
      {prevPost && (
        <InpageNavBlockPrevious>
          <InpageNavBlockTitle>Previous</InpageNavBlockTitle>
          <InpageNavItem>
            <Link to={prevPost.url} title={prevPost.linkTitle}>
              {prevPost.title}
            </Link>
          </InpageNavItem>
        </InpageNavBlockPrevious>
      )}
      {nextPost && (
        <InpageNavBlockNext>
          <InpageNavBlockTitle>Next</InpageNavBlockTitle>
          <InpageNavItem>
            <Link to={nextPost.url} title={nextPost.linkTitle}>
              {nextPost.title}
            </Link>
          </InpageNavItem>
        </InpageNavBlockNext>
      )}
    </InpageNav>
  );
};

InpageNavigation.propTypes = {
  topics: T.array,
  type: T.string,
  prevPost: T.shape({
    url: T.string,
    linkTitle: T.string,
    title: T.string
  }),
  nextPost: T.shape({
    url: T.string,
    linkTitle: T.string,
    title: T.string
  })
};

export default InpageNavigation;
