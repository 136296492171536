import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { themeVal, glsp, divide, media } from '@devseed-ui/theme-provider';
import { Heading } from '@devseed-ui/typography';
import { Button } from '@devseed-ui/button';
import { CollecticonExpandTopRight } from '@devseed-ui/collecticons';

import UniversalGridder from '../../styles/universal-gridder';
import { leading } from '../../styles/typography/leading';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageSuptitle,
  InpageMetaDetails,
  InpageLead,
  InpageBody,
  InpageActions
} from '../../styles/inpage';
import LongForm from '../../components/long-form';
import InpageNavigation from '../../components/inpage-nav';

export const ApplySection = styled(UniversalGridder).attrs({ as: 'section' })`
  position: relative;
  z-index: 1;
`;

export const ApplySectionInner = styled(UniversalGridder).attrs({
  as: 'div',
  grid: {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'content-end'],
    largeUp: ['full-start', 'content-end']
  }
})`
  position: relative;
  z-index: 1;
  grid-row: 1;
  padding: ${glsp(2, 0)};
  background: ${themeVal('color.primary')};
  grid-row-gap: ${glsp(1)};
  color: ${themeVal('color.surface')};

  ${media.smallUp`
    padding: ${glsp(3, 0)};
  `}

  ${media.mediumUp`
    padding: ${glsp(6, 0)};
    grid-row-gap: ${glsp(2)};
    border-radius: 0 ${divide(themeVal('shape.rounded'), 2)}
    ${divide(themeVal('shape.rounded'), 2)} 0;
  `}

  ${media.largeUp`
    padding: ${glsp(8, 0)};
    grid-row-gap: ${glsp(3)};
  `}

  ${media.xlargeUp`
    grid-row-gap: ${glsp(4)};
  `}
`;

export const ApplySectionTitle = styled(Heading).attrs({ as: 'h1' })`
  grid-row: 1;
  grid-column: content-start / span 4;
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 0;

  ${media.mediumUp`
    grid-column: content-start / span 7;
    font-size: 3rem;
    line-height: 3.5rem;
  `}

  ${media.largeUp`
    grid-column: content-3 / span 8;
    font-size: 4rem;
    line-height: 4.5rem;
  `}
`;

export const ApplySectionLead = styled.div`
  ${leading()}
  grid-row: 2;
  grid-column: content-start / span 4;

  ${media.mediumUp`
    grid-column: content-start / span 7;
  `}

  ${media.largeUp`
    grid-column: content-3 / span 8;
  `}

  > *:not(:last-child) {
    margin-bottom: ${glsp(1)};
  }
`;

export const ApplySectionActions = styled.div`
  grid-row: 3;
  grid-column: content-start / span 4;

  ${media.mediumUp`
    grid-column: content-start / span 7;
  `}

  ${media.largeUp`
    grid-column: content-3 / span 8;
  `}
`;

const CareerSingleTemplate = ({ data, location, pageContext }) => {
  const { previous, next } = pageContext;
  const { career } = data;
  const { title, teaser, lead, application } = career.frontmatter;

  const prevPost = previous
    ? {
        url: `/careers/${previous.slug}`,
        linkTitle: 'Visit previous career',
        title: previous.frontmatter.title
      }
    : null;
  const nextPost = next
    ? {
        url: `/careers/${next.slug}`,
        linkTitle: 'Visit next career',
        title: next.frontmatter.title
      }
    : null;

  return (
    <Layout location={location} title={title} description={teaser}>
      <Inpage>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>{title}</InpageTitle>
              <InpageSuptitle>
                <Link to='/careers' title='View Careers page'>
                  Careers
                </Link>
              </InpageSuptitle>
              <InpageMetaDetails>
                <dt>Location</dt>
                <dd>{career.frontmatter.location}</dd>
              </InpageMetaDetails>
            </InpageHeadline>
            {lead && (
              <InpageLead>
                <p>{lead}</p>
              </InpageLead>
            )}
            {application && application.url && (
              <InpageActions>
                <Button
                  forwardedAs='a'
                  variation='primary-fill'
                  size='xlarge'
                  href={application.url}
                  title='Apply now'
                >
                  <CollecticonExpandTopRight /> Apply for this job
                </Button>
              </InpageActions>
            )}
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody>
          <LongForm dangerouslySetInnerHTML={{ __html: career.html }} />
          {application && application.url && (
            <ApplySection>
              <ApplySectionInner>
                <ApplySectionTitle>Apply now</ApplySectionTitle>
                <ApplySectionLead>
                  <p>Take DevSeed to the next level of global impact.</p>
                </ApplySectionLead>
                <ApplySectionActions>
                  <Button
                    forwardedAs='a'
                    variation='achromic-outline'
                    size='xlarge'
                    href={application.url}
                    title='Apply now'
                  >
                    <CollecticonExpandTopRight /> Apply for this job
                  </Button>
                </ApplySectionActions>
              </ApplySectionInner>
            </ApplySection>
          )}
          <InpageNavigation prevPost={prevPost} nextPost={nextPost} />
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default CareerSingleTemplate;

CareerSingleTemplate.propTypes = {
  data: T.object,
  location: T.object,
  application: T.shape({
    url: T.string
  }),
  pageContext: T.shape({
    id: T.string,
    next: T.object,
    previous: T.object
  })
};

export const pageQuery = graphql`
  query CareerById($id: String!) {
    career(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        teaser
        lead
        location
        application {
          url
        }
      }
    }
  }
`;
