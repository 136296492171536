module.exports.blogTopics = [
  { id: 'all', label: 'All Posts' },
  { id: 'team', label: 'Team' },
  { id: 'data-ai-ml', label: 'Data & AI/ML' },
  { id: 'environment', label: 'Environment' },
  { id: 'society', label: 'Society' },
  { id: 'cloud-computing', label: 'Cloud Computing & Infrastructure' },
  { id: 'geospatial', label: 'Geospatial Technology & Products' }
];

module.exports.projectsTopics = [
  { id: 'all', label: 'All' },
  { id: 'labs', label: 'Labs' },
  { id: 'data-ai-ml', label: 'Data & AI/ML' },
  { id: 'environment', label: 'Environment' },
  { id: 'society', label: 'Society' },
  { id: 'cloud-computing', label: 'Cloud Computing & Infrastructure' },
  { id: 'geospatial', label: 'Geospatial Technology & Products' }
];
