import React from 'react';
import get from 'lodash.get';

import { blogTopics, projectsTopics } from '../../gatsby-node/taxonomy';

/**
 * Calculates the integer remainder of a division of a by n, handling negative
 * modulo in the mathematically expected way.
 *
 * This is very helpful for cycling array indexes.
 * If the current index is the first, the last is returned, and vice-versa.
 *
 * Given an index if we want to know the previous:
 * @example
 *   const arr = [1, 2, 3];
 *   const arrIdx = 0;
 *   const newIdx = mod(arrIdx - 1, arr.length); // 2
 *
 * @param {number} a Dividend
 * @param {number} n Divisor
 */
export function mod(a, n) {
  return ((a % n) + n) % n;
}

/**
 * Created a validator function that ensures a number is within the given range.
 *
 * @param {number} min Range lower bound (inclusive)
 * @param {number} max Range upper bound (inclusive)
 *
 * @returns {function} Validator function.
 */
export function validateRangeNum(min, max) {
  return (raw) => {
    const value = Number(raw);
    return !isNaN(value) && raw !== '' && value >= min && value <= max;
  };
}

/**
 * Compares two values using JSON stringification.
 *
 * @param {mixed} a Data to compare
 * @param {mixed} b Data to compare
 */
export function isEqualObj(a, b) {
  // Exist early if they're the same.
  if (a === b) return true;
  return JSON.stringify(a) === JSON.stringify(b);
}

/**
 * Create a date which matches the input date offsetting the timezone to match
 * the user's.
 * If the user is in UTC-5 time and the date string is in UTC the date will be
 * constructed disregarding the input date's timezone.
 * Ex:
 * input: 2019-01-01T00:00:00Z
 * normal output: 2018-12-31T19:00:00 -05:00
 * utcDate output: 2019-01-01T00:00:00 -05:00
 *
 * Basically the real date gets changed by the timezone offset.
 * Times I had timezone related bugs and this fn saved me: 4
 *
 * @param {string} str Date String
 *
 * @returns Date
 */
export function utcDate(str) {
  const date = new Date(str);
  // If the date is not valid, return it and be done.
  if (isNaN(date.getTime())) return date;
  const offset = date.getTimezoneOffset();
  date.setTime(date.getTime() + offset * 60 * 1000);
  return date;
}

/**
 * Removes given props from the component returning a new one.
 * This is used to circumvent a bug with styled-component where unwanted props
 * are passed to the dom causing react to display an error:
 *
 * ```
 *   `Warning: React does not recognize the hideText prop on a DOM element.
 *   If you intentionally want it to appear in the DOM as a custom attribute,
 *   spell it as lowercase hideText instead. If you accidentally passed it from
 *   a parent component, remove it from the DOM element.`
 * ```
 *
 * This commonly happens when an element is impersonating another with the
 * `as` prop:
 *
 *     <Button hideText forwardedAs={Link}>Home</Button>
 *
 * Because of a bug, all the props passed to `Button` are passed to `Link`
 * without being filtered before rendering, causing the aforementioned error.
 *
 * This utility creates a component that filter out unwanted props and can be
 * safely used as an impersonator.
 *
 *     const CleanLink = filterComponentProps(Link, ['hideText'])
 *     <Button hideText forwardedAs={CleanLink}>Home</Button>
 *
 * Issue tracking the bug: https://github.com/styled-components/styled-components/issues/2131
 *
 * Note: The props to filter out are manually defined to reduce bundle size,
 * but it would be possible to automatically filter out all invalid props
 * using something like @emotion/is-prop-valid
 *
 * @param {object} Comp The react component
 * @param {array} filterProps Props to filter off of the component
 */
export function filterComponentProps(Comp, filterProps = []) {
  const isValidProp = (p) => !filterProps.includes(p);

  return React.forwardRef(function FilteredComponent(rawProps, ref) {
    const props = Object.keys(rawProps).reduce(
      (acc, p) => (isValidProp(p) ? { ...acc, [p]: rawProps[p] } : acc),
      {}
    );
    return <Comp ref={ref} {...props} />;
  });
}

/**
 * Performs a request to the given url returning the response in json format
 * or throwing an error.
 *
 * @param {string} url Url to query
 * @param {object} options Options for fetch
 */
export async function fetchJSON(url, options) {
  let response;
  try {
    response = await fetch(url, options);
    const json = await response.json();

    if (response.status >= 400) {
      const err = new Error(json.message);
      err.statusCode = response.status;
      err.data = json;
      throw err;
    }

    return { body: json, headers: response.headers };
  } catch (error) {
    error.statusCode = response ? response.status || null : null;
    throw error;
  }
}

/**
 * Prepare author properties based on raw authors data.
 * @param {Array} rawAuthors - The raw authors data from the GraphQl query.
 *
 * @returns {Array} - An array of prepared author properties.
 */
export function prepareAuthorProperties(rawAuthors) {
  return rawAuthors?.length
    ? rawAuthors.map((a) => ({
        /**
         * The slug of the author.
         * @type {string}
         */
        slug: a.slug,

        /**
         * The name of the author.
         * @type {string}
         */
        name: a.frontmatter.title,

        /**
         * The expertise of the author.
         * @type {string}
         */
        expertise: a.frontmatter.expertise,

        /**
         * The avatar of the author.
         * @type {string}
         */
        avatar: get(a, 'frontmatter.media.avatar.url.childImageSharp.fixed')
      }))
    : [];
}

export function getBlogTopicUrl(name) {
  const topic = blogTopics.find((topic) => topic.label === name);

  // If not topic, just use the value to show a 404
  return topic ? `/blog/topics/${topic.id}` : `/blog/topics/${name}`;
}

export function getProjectTopicUrl(name) {
  const topic = projectsTopics.find((topic) => topic.label === name);

  // If not topic, just use the value to show a 404
  return topic ? `/projects/topics/${topic.id}` : `/projects/topics/${name}`;
}
